<template>
    <v-stepper-content  class="" step="1" color="primary">
        <v-row align="center" class="py-4 px-4">
            <v-col lg="3" v-for="(item, index) in planList" :key="index">
                <v-card  class="text-center  py-4 shadow-none " 
                
                :color=" item.popular ? 'amber lighten-4' : '' " >
                    <div class="popular" v-if="item.popular">
                        POPULAR
                    </div>
                    <h3 class="font-weight-bold"> {{ item.plan }}</h3>
                    <p class="text-left px-4 "> {{item.description }} </p>
                    <v-btn  min-width="150" v-if="select && select.plan == item.plan"  color="#00919B"  @click="select = item"  class="mt-4 shadow text-white">
                        SELECTED
                    </v-btn>
                    <v-btn v-else color="#0A009B" @click="select = item"  outlined class="mt-4" >
                        Choose Plan
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-btn text small class="text-secondary" @click="cancel">
            Cancel
        </v-btn>
        <v-btn
        color="#0A009B"
        text
        :disabled="!select"
        @click="next"
        >
        next
        </v-btn>
    </v-stepper-content>
</template>
<script>
export default {
  props: {
      plan : {
          type : Array,
          default() {
              return []
          }
      }
  },
  data()
  {
      return {
          select : null,
      }
  },
  computed : {
      planList() {
          var ar = [];
          if(this.plan.length > 0)
          {
                for(var i in this.plan)
                {
                    if(!isNaN(i))
                    {
                        var f = ar.filter( c =>  {
                            return c.plan == this.plan[i].plan
                        })
                        if(f.length < 1)
                        {
                            ar.push(this.plan[i])
                        }
                    }
                } 
          }
        return ar
      }
  },
  methods : {
    next()
    {
        this.$emit('step2', this.select)
    },
    cancel()
    {
        this.$router.push({path: '/account/servicessubscription'})
    },
  }

    
}
</script>
<style scoped>
    
  
</style>