<template>
  <v-stepper-content step="3">
    <v-row class="p-0 align-center" v-if="!status.submit && !status.free">
      <v-col md="4" class="" v-if="product">
        <v-card class="shadow-none" outlined :disabled="status.paypal">
          <v-overlay :absolute="true" :z-index="2" :value="loading.paypal">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title> Paypal </v-card-title>
          <div>
            <v-img
              width="130"
              height="130"
              class="mx-auto"
              src="@/assets/Svg/paypal.svg"
            >
            </v-img>
          </div>
          <v-card-text>
            <div class="d-flex align-center flex-wrap">
              <p class="text-dark w-50 mb-0">
                <small class="d-block"> Total </small>
                <b class="black--text"
                  >{{ display.total_payment | currency(true) }}
                </b>
                <small class="text-danger" v-if="computations.wallet_input"
                  >- {{ computations.wallet_input | currency(true) }}</small
                >
              </p>
              <p class="text-dark w-50 mb-0">
                <small class="d-block"> Remaining Payment </small>
                <b class="black--text"> {{ billBalance | currency(true) }} </b>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" v-if="wallet">
        <v-card class="shadow-none py-1" outlined :disabled="status.wallet">
          <v-card-title> Your Wallet </v-card-title>
          <v-card-text>
            <div class="mb-2">
              <p @click="openInfo" class="blue--text mb-2">
                <a>
                  Refer more people and get wallet income that you can user to
                  pay for your bill.
                </a>
              </p>
              <div class="d-flex align-center flex-wrap">
                <p class="text-dark w-50 mb-0">
                  <small class="d-block"> Current Balance </small>
                  <b class="black--text">
                    {{ wallet.balance | currency(true) }}
                  </b>
                  <small class="text-danger" v-if="computations.wallet_input"
                    >- {{ computations.wallet_input | currency(true) }}
                  </small>
                </p>
                <p class="text-dark w-50 mb-1">
                  <small class="d-block"> Remaining </small>
                  <b class="black--text">
                    {{ walletBalance | currency(true) }}</b
                  >
                </p>
              </div>
            </div>
            <div>
              <p class="mb-2 black--text">
                You can use your earnings or wallet for payment.
              </p>
              <v-text-field
                label="Enter Amount"
                persistent-hint=""
                type="number"
                hide-details=""
                hint=""
                min="0"
                @keyup="computeWallet"
                :max="walletMax()"
                required
                v-model="computations.wallet_input"
              >
              </v-text-field>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="product">
      <v-col cols="8" v-if="!status.free">
        <v-overlay :absolute="true" :z-index="2" :value="loading.submit">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card
          flat
          :class="!status.only_wallet ? 'text-left paypalIndex' : 'd-none'"
          v-if="!status.submit"
        >
          <p class="black--text">
            Pay via PayPal. Please do not close the browser while the payment
            process is in progress.
          </p>
          <div id="paypal-button"></div>
        </v-card>
        <v-card
          flat
          :class="status.only_wallet ? 'text-left' : 'd-none '"
          v-if="!status.submit"
        >
          <p class="black--text">Payment using my wallet.</p>
          <v-btn color="#0A009B" outlined block @click="accounting">
            Pay Now
          </v-btn>
        </v-card>
        <div v-if="status.submit" class="rounded text-center py-5">
          <v-img
            width="130"
            height="130"
            class="mx-auto mb-5"
            src="@/assets/checked.png"
          >
          </v-img>
          <div>
            <h4 class="text-center mt-2 text-uppercase rounded mb-0">
              Payment Successful
            </h4>
            <p class="text-center mb-2 text-secondary">
              Thank you for Subscribing
            </p>
          </div>
        </div>
      </v-col>
      <v-col v-else md="8">
        <v-overlay :absolute="true" :z-index="2" :value="loading.submit">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div v-if="status.submit" class="rounded text-center py-5">
          <v-img
            width="130"
            height="130"
            class="mx-auto mb-5"
            src="@/assets/checked.png"
          >
          </v-img>
          <h4 class="text-center mt-2 text-uppercase rounded mb-0">
            Thank you for Subscribing
          </h4>
        </div>
        <div v-else>
          <p class="black--text">
            This is a free plan. please select subscribe to start using
            {{ product.name }}
          </p>
          <v-btn color="#0A009B" outlined block @click="accounting">
            Subscribe
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div class="mt-4">
      <v-btn
        text
        small
        class="text-secondary"
        @click="cancel"
        :disabled="status.submit"
      >
        Back
      </v-btn>
      <v-btn color="#0A009B" text @click="next" :disabled="!status.submit">
        Finish
      </v-btn>
    </div>
    <input type="hidden" id="payAmount" />
    <button
      type="button"
      id="triggerPaypalSuccess"
      class="hidden"
      @click="setTransactionId"
    ></button>
    <button
      type="button"
      id="paypalOff"
      class="hidden"
      @click="paypalOff(true)"
    ></button>
    <button
      type="button"
      id="paypalOff"
      class="hidden"
      @click="paypalOff(false)"
    ></button>
    <input type="text" id="paypalTransactionData" class="hidden" />
  </v-stepper-content>
</template>
<script>
export default {
  props: {
    step: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    wallet: {
      type: Object,
      default() {
        return {};
      },
    },
    payment_method: {
      type: Array,
      default() {
        return [];
      },
    },
    my_info: {
      type: Object,
      default() {
        return {};
      },
    },
    inviter: {
      type: Object,
      default() {
        return {};
      },
    },
    common: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    step: function (step) {
      if (step == 3) {
        this.trigger();
      }
    },
  },
  data() {
    return {
      display: {
        total_payment: 0,
      },
      computations: {
        wallet_input: null,
        total: 0,
      },
      status: {
        paypal: false,
        paypal_rendered: false,
        submit: false,
        only_wallet: false,
        back: false,
      },
      loading: {
        paypal: false,
        wallet: false,
        submit: false,
      },
      info: {
        paypal_transaction_id: null,
        payment: 0,
        inviter: null,
        start_of_subscription: null,
        end_of_subscription: null,
        subs_plan_id: null,
      },
    };
  },
  computed: {
    walletBalance() {
      var b = this.wallet.balance - this.computations.wallet_input;

      if (b > 0) {
        return b;
      } else {
        return 0;
      }
    },
    billBalance() {
      var b = this.display.total_payment - this.computations.wallet_input;

      if (b > 0) {
        return b;
      } else {
        return 0;
      }
    },
    getPlan() {
      return this.product.subs_plan.filter((c) => {
        return (
          c.plan == this.common.selected_plan.plan &&
          c.periodical == this.common.subscription_plan.periodical
        );
      });
    },
  },
  methods: {
    cancel() {
      this.$emit("stepBack", true);
    },
    next() {
      this.$router.push({ path: "/account/servicessubscription" });
    },
    trigger() {
      if (this.common) {
        if (this.common.subscription_plan) {
          this.display.total_payment = this.common.subscription_plan.amount;
          document.getElementById("payAmount").value =
            this.common.subscription_plan.amount;
        }
      }
      this.checkPaypal();
      this.computeWallet();
      this.info.subs_plan_id =
        this.getPlan.length > 0 ? this.getPlan[0].id : "";

      if (
        this.getPlan.length > 0 &&
        this.getPlan[0].plan.toLowerCase() == "free"
      ) {
        this.status.free = true;
      }
    },
    checkPaypal() {
      var intervalPaypalChecker = setInterval(checker(), 1000);
      let rendered = false;

      function checker() {
        if (document.getElementsByClassName("paypal-button-text")) {
          rendered = true;
          clearInterval(intervalPaypalChecker);
        }
      }
      if (rendered) {
        this.status.paypal = false;
      }

      if (!this.status.paypal_rendered) {
        this.initPaypal();
      }
    },
    initPaypal() {
      this.paypalOff(true);
      const checkout = this.$config.api + "checkout-paypal";
      const execute = this.$config.api + "execute-paypal";
      const environment = this.$config.paypal.production
        ? "production"
        : "sandbox";
      const client_id = this.$config.paypal.client_id;
      const return_url = this.$config.home;

      window.paypal.Button.render(
        {
          env: environment,
          client: {
            sandbox: client_id,
            production: client_id,
          },
          locale: "en_US",
          style: {
            size: "responsive",
            color: "gold",
            shape: "pill",
            label: "paypal",
            tagline: "false",
          },
          experience: {
            input_fields: {
              no_shipping: 1,
            },
          },
          commit: true, // Optional: show a 'Pay Now' button in the checkout flow

          payment: function (actions) {
            let returnUrl = return_url;
            let amount = document.getElementById("payAmount").value;
            return actions.request
              .post(checkout, { return_url: returnUrl, amount: amount })
              .then((res) => {
                return res.id;
              });
          },
          onAuthorize: function (data, actions) {
            return actions.request
              .post(execute, {
                payment_id: data.paymentID,
                payer_id: data.payerID,
              })
              .then(function (data) {
                document.getElementById("paypalTransactionData").value =
                  data.details.transactions[0].related_resources[0].sale.id;
                document.getElementById("triggerPaypalSuccess").click();
              });
          },
        },
        "#paypal-button"
      );
      this.status.paypal_rendered = true;
      this.paypalOff(false);
    },
    paypalOff(bool) {
      this.status.paypal = bool ? true : false;
      this.loading.paypal = bool ? true : false;
    },
    openInfo() {
      let routeData = this.$router.resolve({ name: "YourInfo" });
      window.open(routeData.href, "_blank");
    },
    computeWallet() {
      if (this.wallet) {
        if (
          this.computations.wallet_input >= this.display.total_payment ||
          this.computations.wallet_input >= this.wallet.balance
        ) {
          this.computations.wallet_input = this.display.total_payment;

          this.status.only_wallet = true;
        } else {
          this.status.only_wallet = false;
        }
        if (this.status.only_wallet) {
          document.getElementById("payAmount").value =
            this.computations.wallet_input;
        } else {
          document.getElementById("payAmount").value = this.billBalance;
        }
      }
    },
    walletMax() {},
    setTransactionId() {
      this.info.paypal_transaction_id = document.getElementById(
        "paypalTransactionData"
      ).value;
      this.accounting();
    },
    accounting() {
      this.loading.submit = true;
      this.disableButton(true);
      this.calculateSubscription();
      this.calculateReferral();
      let data = {
        payment_method_id: this.info.paypal_transaction_id
          ? this.checkPaymentMethod("paypal")
          : null,
        payment_method_wallet: this.computations.wallet_input
          ? this.checkPaymentMethod("wallet")
          : null,
        wallet_amount: parseFloat(
          this.$options.filters.amount(this.computations.wallet_input)
        ),
        product_id: this.product.id,
        user_id: this.my_info.id,
        transaction_date: this.$options.filters.apiDate(),
        payment_amount: parseFloat(
          this.$options.filters.amount(this.billBalance)
        ),
        total_amount: parseFloat(
          this.$options.filters.amount(
            this.billBalance + this.computations.wallet_input
          )
        ),
        type: 1,
        paypal_transaction_id: this.info.paypal_transaction_id,
        inviter: this.info.inviter,
        forStorage: [
          {
            subs_plan_id: this.info.subs_plan_id,
            id: this.product.subscription_id,
            products_id: this.product.id,
            start_of_subscription: this.$options.filters.apiDate(
              this.product.end_of_subscription
            ),
            end_of_subscription: this.$options.filters.apiDate(
              this.info.end_of_subscription
            ),
            user_company_id: this.my_info.current_user_company_id,
            subscription_amount: parseFloat(
              this.$options.filters.amount(this.display.total_payment)
            ),
            transaction_id: null,
            status: "paid",
          },
        ],
        product_type: this.product.type,
        company_id: this.my_info.default_user_company.company_id,
      };

      this.axios
        .post("accounting", data)
        .then(() => {
          this.status.submit = true;
          this.loading.submit = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading.submit = false;
          this.disableButton(false);
        });
    },
    calculateReferral() {
      if (this.inviter && this.inviter.referror) {
        let referral_status = this.$options.filters.checkInviterExpirationDate(
          this.inviter.date_time_created
        );
        if (!referral_status) {
          let product_refferal_type = this.product.referral_type;
          let product_referral_amount = this.product.referral_amount;
          let total = 0;
          if (product_refferal_type == 0) {
            let percentage = product_referral_amount / 100;
            total = this.computations.total * percentage;
          }
          if (product_refferal_type == 1) {
            total = product_referral_amount;
          }
          this.info.inviter = {
            user_id: this.inviter.referror.id,
            amount: this.$options.filters.amount(total),
            invited_company: this.inviter.company.name,
          };
        }
      }
    },
    checkPaymentMethod(method) {
      let m = null;

      for (let i in this.payment_method) {
        if (this.payment_method[i].name == method) {
          m = this.payment_method[i].id;
        }
      }
      if (m) {
        return m;
      } else {
        return 1;
      }
    },
    calculateSubscription() {
      var sub = this.common.subscription_plan.periodical.split(" ");
      var type = "";
      for (let i of sub) {
        type = type + i.charAt(0);
      }
      this.info.end_of_subscription =
        this.$options.filters.getSubsPlanSubscription(
          this.product.end_of_subscription,
          type
        );
    },
    disableButton(bool) {
      this.status.paypal = bool ? true : false;
      this.status.wallet = bool ? true : false;
    },
  },
};
</script>